var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resume-file-upload" },
    [
      _vm.uploadType
        ? _c(
            "el-upload",
            {
              ref: "uploader",
              staticClass: "file-uploader",
              attrs: {
                drag: "",
                "file-list": _vm.fileList,
                accept: ".htm,.html,.txt,.docx,.doc,.pdf",
                "show-file-list": false,
                "on-success": _vm.handleUploadSuccess,
                "on-change": _vm.handleFileListChange,
                "on-remove": _vm.handleRemove,
                data: _vm.upLoadData,
                "http-request": _vm.handleBatchUpload,
                "auto-upload": true,
                action: "/My/Resume/MultipleUpload",
                limit: 20,
                "on-exceed": _vm.handleFileLimit,
                multiple: "",
              },
            },
            [
              _c("font-icon", {
                staticClass: "el-icon__upload",
                attrs: { href: "#icon-resume_ic_uppload_large" },
              }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("点击或拖拽上传多份简历，一次最多20份"),
              ]),
              _c("div", { staticClass: "el-upload__label" }, [
                _vm._v("（仅支持*.htm，*.html，*.docx，*.pdf，*.txt格式）"),
              ]),
              _c("div", { staticClass: "el-upload__text tip" }, [
                _c("em", [_vm._v("Tips：")]),
                _vm._v("上传完成后可在Talentpool进行查看\n        "),
              ]),
            ],
            1
          )
        : !_vm.uploadType && _vm.excelStep == 1
        ? _c(
            "el-upload",
            {
              ref: "uploader",
              staticClass: "file-uploader",
              attrs: {
                drag: "",
                "file-list": _vm.fileList,
                accept: ".xlsx,.xls",
                "show-file-list": false,
                "on-success": _vm.handleUploadSuccess,
                "on-change": _vm.handleFileListChange,
                "on-remove": _vm.handleRemove,
                "auto-upload": false,
                "http-request": _vm.handleExcelUpload,
                action: "/My/Candidate/Excel/MatchColumn",
              },
            },
            [
              _c("font-icon", {
                staticClass: "el-icon__upload",
                attrs: { href: "#icon-resume_ic_uppload_large" },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.enableNext,
                      expression: "!enableNext",
                    },
                  ],
                  staticClass: "el-upload__text",
                },
                [_vm._v("\n            点击或拖拽上传Excel\n        ")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.enableNext,
                      expression: "enableNext",
                    },
                  ],
                  staticClass: "upload-file__list",
                },
                _vm._l(_vm.fileList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "file" },
                    [
                      _c("span", { staticClass: "file-name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("font-icon", {
                        staticClass: "el-icon__delete",
                        attrs: { href: "#icon-quxiao" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleRemoveFile.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _c("div", { staticClass: "el-upload__text tip" }, [
                _c("em", [_vm._v("Tips：")]),
                _vm._v(
                  "：1、可直接上传自己的Excel。2、姓名、手机" +
                    _vm._s(
                      this.upLoadData.IsFirmResume ? "、城市、公司、职位" : ""
                    ) +
                    "为必填项。3、只识别文件的第一个Sheet。\n        "
                ),
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.uploadType &&
                        ((_vm.excelStep == 1 && _vm.enableNext) ||
                          _vm.excelStep == 2),
                      expression:
                        "!uploadType && ((excelStep == 1 && enableNext ) || excelStep == 2)",
                    },
                  ],
                  staticClass: "lbd-upload__next",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.excelUploadNext.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            下一步\n        ")]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.uploadType
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.excelStep == 2,
                  expression: "excelStep == 2",
                },
              ],
              staticClass: "excel-match",
            },
            [
              _c(
                "div",
                { staticClass: "excel-match-content" },
                [
                  _c("span", { staticClass: "excel-match-title" }, [
                    _vm._v("\n                检测结果：\n            "),
                  ]),
                  _c("field-match", {
                    ref: "fieldMatch",
                    attrs: {
                      leftFieldTitle: "Excel字段",
                      rightFieldTitle: "系统字段",
                      leftFields: _vm.excelFields,
                      rightFields: _vm.systemFields,
                      rightSortRule: _vm.systemSortRule,
                    },
                    on: { "field-match-result": _vm.excelMatchResult },
                  }),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.uploadType &&
                        ((_vm.excelStep == 1 && _vm.enableNext) ||
                          _vm.excelStep == 2),
                      expression:
                        "!uploadType && ((excelStep == 1 && enableNext ) || excelStep == 2)",
                    },
                  ],
                  staticClass: "lbd-upload__next",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.excelUploadNext.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            下一步\n        ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "layer-analyze",
        },
        [_c("div", { staticClass: "screen" }), _vm._m(0)]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "layer-body" }, [
      _c("div", { staticClass: "icon-analyze" }),
      _c("span", [_vm._v("上传中…")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }