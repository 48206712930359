<template>
    <div class="field-match">
        <ul>
            <li class="match-item match-title">
                <div class="field-item field-item-left field-title">
                    {{leftFieldTitle}}
                </div>
                <span class="field-item-space"></span>
                <div class="field-item field-item-right field-title field-title-right">
                    {{rightFieldTitle}}
                </div>
            </li>
            <li
                class="match-item"
                v-for="(field, fieldIndex) in leftFields"
                :key="fieldIndex"
            >
                <div class="field-item field-item-left">
                    <i :class="'field-icon el-icon-' + matchResule(field, fieldIndex)"></i>
                    {{field.importItemName}}
                </div>
                <span class="field-item-space"></span>
                <div class="field-item field-item-right">
                    <el-popover
                        placement="right"
                        v-model="field.isShowSelect"
                        popper-class="match-select-popover"
                        trigger="click"
                        :visible-arrow="false"
                    >
                        <div
                            slot="reference"
                            class="field-item-title"
                            @click.prevent="handleShowSelect($event, fieldIndex)"
                        >
                            <span
                                class="matching-text"
                                :class="{'matching-text-error': field.errMessage || !field.itemName, 'matching-required': field.required}"
                            >
                                {{field.itemName ? field.itemName : '请选择'}}
                            </span>
                            <i class="select-icon el-icon-caret-right"></i>
                            <span class="matching-error">{{field.errMessage}}</span>
                        </div>
                        <div class="item-system-select">
                            <div
                                class="select-item"
                                :class="field.itemName ? '' : 'select-item-selected'"
                                @click="handleMatch({}, fieldIndex)"
                            >
                                请选择
                            </div>
                            <div
                                class="select-item"
                                :class="{'select-item-selected': item.name == field.itemName, 'select-item-required': item.required}"
                                v-for="(item, index) in rightFields"
                                :key="index"
                                @click="handleMatch(item, fieldIndex)"
                            >
                                {{item.name}}
                            </div>
                        </div>
                    </el-popover>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        leftFieldTitle: String,
        rightFieldTitle: String,
        leftFields: Array,
        rightFields: Array,
        rightSortRule: Object
    },
    data() {
        return {
            matchSuccess: true,
            errorTip: ""
        }
    },
    watch: {
	    matchSuccess(val) {
	        this.$emit('field-match-result', this.errorTip);
        }
    },
    mounted() {
        this.matchSuccess = this.handleCheck();
    },
    methods: {
        init() {
            let repititionIndexs = [];
            let rightFieldSortList = this.rightSortRule;
            this.rightFields.forEach(right => {
                right.sortCount = rightFieldSortList[right.property] !== undefined ? rightFieldSortList[right.property] : 99;
                for(let i = 0; i < this.leftFields.length; i ++) {
                    if(right.name == this.leftFields[i].itemName) {
                        this.$set(this.leftFields[i], 'required', right.required);
                        if(!right.mutiMatch) {
                            repititionIndexs.push(i);
                        }
                    }
                }
                if(repititionIndexs.length > 1) {
                    for(let j = 0; j < repititionIndexs.length; j++) {
                        this.$set(this.leftFields[repititionIndexs[j]], 'errMessage', `* ${right.name}不可重复选择`);
                    }
                }
                repititionIndexs = [];
            })
            this.rightFields.sort((item1, item2) => {
                return (item1.sortCount - item2.sortCount);
            })
        },
        matchResule(field, fieldIndex) {
            let iconName = "";
            if(!field.itemName) {
                //请选择
                iconName = 'warning-outline';
            } else {
                if(field.errMessage) {
                    let matchingError = false;
                    //之前选择的时候有错误 需要重新判断现在是否还存在错误
                    this.leftFields.forEach((left, leftIndex) => {
                        if(field.itemName == left.itemName && fieldIndex !== leftIndex) {
                            //由于错误在组件的错误类型只有重复选择一种错误 故只要非当前项已被选中则错误依旧存在
                            iconName = 'circle-close';
                            matchingError = true;
                        } else if(!matchingError && leftIndex == this.leftFields.length - 1){
                            //除去当前项已没有被选中的项 错误取消
                            this.leftFields[fieldIndex].errMessage = "";
                            iconName = 'circle-check';
                        }
                    })
                } else {
                    iconName = 'circle-check';
                }
            }
            this.matchSuccess = this.handleCheck();
            return iconName;
        },
        handleMatch(field, leftFieldIndex) {
            if(!field.name) {
                this.leftFields[leftFieldIndex].itemName = "";
                this.leftFields[leftFieldIndex].errMessage = "";
                this.leftFields[leftFieldIndex].required = false;
            } else {
                this.leftFields[leftFieldIndex].itemName = field.name;
                this.leftFields[leftFieldIndex].required = field.required;
                if(!field.mutiMatch) {
                    let matchingError = false;
                    this.leftFields.forEach((left, leftIndex) => {
                        if(left.itemName == field.name && leftIndex !== leftFieldIndex) {
                            // 不可多选的项进行选择之后需要判断其他项中是否该项已经被选中
                            left.errMessage = this.leftFields[leftFieldIndex].errMessage = `* ${field.name}不可重复选择`;
                            matchingError = true;
                        } else if(!matchingError && leftIndex == this.leftFields.length - 1){
                            this.leftFields[leftFieldIndex].errMessage = "";
                        }
                    })
                }
            }
            this.leftFields[leftFieldIndex].isShowSelect = false;
        },
        handleShowSelect(ev, fieldIndex) {
            setTimeout(() => {
                this.$set(this.leftFields[fieldIndex], 'isShowSelect', true);
            })
        },
        handleCheck() {
            let requiredList = [],  //必选项
                requiredErrorCount = 0, //必选项没有填写的数量
                requiredError = false,  //是否有必选项没有填写的错误
                repititionError = false;    //是否有唯一项被重复选择的错误
            this.rightFields.forEach((right, rightIndex) => {
                if(right.required) {
                    requiredList.push(right.name);
                    let hasRequiredSelected = false;
                    this.leftFields.forEach((left, leftIndex) => {
                        if(left.itemName == right.name) {
                            hasRequiredSelected = true;
                        }
                    })
                    if(!hasRequiredSelected) {
                        requiredErrorCount++;
                    }
                }
            });
            if(requiredList.length > 0 && requiredErrorCount > 0) {
                let requiredErrorMsg = "";
                requiredList.forEach((item, index) => {
                    requiredErrorMsg += `“${item}”、`;
                    if(index == requiredList.length - 1) {
                        requiredErrorMsg = requiredErrorMsg.replace(/\u3001$/ig, "");
                        requiredErrorMsg += "为必选项";
                    }
                })
                this.errorTip = requiredErrorMsg;
                return false;
            }
            this.leftFields.forEach((left, leftIndex) => {
                if(left.errMessage && left.errMessage !== "") {
                    repititionError = true;
                }
            });
            if(repititionError) {
                this.errorTip = '匹配项存在错误，请重新匹配';
                return false;
            }
            this.errorTip = null;
            return true;
        }
    }
};
</script>

<style lang="scss" scope>
.field-match {
    display: inline-block;
    .match-item {
        display: flex;
        justify-content: space-between;
        height: 34px;
        line-height: 34px;
        font-size: 14px;
        color: #666;
        &.match-title {
            margin-bottom: 8px;
            font-size: 16px;
            color: #444;
        }
        .field-item {
            &-left {
                min-width: 94px;
                display: flex;
            }
            &-right {
                min-width: 108px;
            }
            .field-icon {
                font-size: 16px;
                align-self: center;
                margin-right: 8px;
                &.el-icon-circle-check {
                    color: $primary;
                }
                &.el-icon-circle-close {
                    color: #FC7859;
                }
                &.el-icon-warning-outline {
                    color: #F5A623;
                }
            }
            .field-item-title {
                position: relative;
                text-align: right;
                cursor: pointer;
                .matching-error, .matching-text-error {
                    color: #FC7859;
                }
                .matching-required {
                    &::before {
                        content: "*";
                        color: #FC7859;
                    }
                }
                .select-icon {
                    font-size: 20px;
                    color: #ddd;
                    vertical-align: middle;
                }
                .matching-error {
                    font-size: 12px;
                    position: absolute;
                    left: 114px;
                    top: 0;
                    white-space: nowrap;
                }
            }
        }
        .field-item-space {
            flex-grow: 1;
            min-width: 240px;
            border-top: 1px dashed #ddd;
            height: 1px;
            align-self: center;
        }
        .field-title {
            color: #444;
        }
        .field-title-right {
            text-align: right;
            &.field-title {
                padding-right: 24px;
            }
        }
    }
}
</style>
<style lang="scss">
.match-select-popover.el-popover {
    min-width: 100px;
    padding: 0;
    .item-system-select {
        max-height: 358px;
        overflow-y: auto;
        .select-item {
            height: 40px;
            padding-left: 20px;
            line-height: 40px;
            color: #666;
            position: relative;
            &:hover {
                color: $primary;
            }
            &.select-item-selected {
                background-color: rgba(56, 188, 157, 0.12);
            }
            &.select-item-required {
                &::before {
                    content: "*";
                    color: #FC7859;
                    position: absolute;
                    left: 9px;
                    top: 2px;
                }
            }
        }
    }
}
</style>