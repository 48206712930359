<template>
    <div class="resume-file-upload">
        <el-upload
            v-if="uploadType"
            ref="uploader"
            class="file-uploader"
            drag
            :file-list="fileList"
            accept=".htm,.html,.txt,.docx,.doc,.pdf"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :on-change="handleFileListChange"
            :on-remove="handleRemove"
            :data="upLoadData"
            :http-request="handleBatchUpload"
            :auto-upload="true"
            action="/My/Resume/MultipleUpload"
            :limit="20"
            :on-exceed="handleFileLimit"
            multiple
        >
            <font-icon class="el-icon__upload" href="#icon-resume_ic_uppload_large"></font-icon>
            <div class="el-upload__text">点击或拖拽上传多份简历，一次最多20份</div>
            <div class="el-upload__label">（仅支持*.htm，*.html，*.docx，*.pdf，*.txt格式）</div>
            <div class="el-upload__text tip">
                <em>Tips：</em>上传完成后可在Talentpool进行查看
            </div>
        </el-upload>

        <el-upload
            v-else-if="!uploadType && excelStep == 1"
            ref="uploader"
            class="file-uploader"
            drag
            :file-list="fileList"
            accept=".xlsx,.xls"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :on-change="handleFileListChange"
            :on-remove="handleRemove"
            :auto-upload="false"
            :http-request="handleExcelUpload"
            action="/My/Candidate/Excel/MatchColumn"
        >
            <font-icon class="el-icon__upload" href="#icon-resume_ic_uppload_large"></font-icon>
            <div class="el-upload__text" v-show="!enableNext">
                点击或拖拽上传Excel
            </div>
            <div class="upload-file__list" v-show="enableNext">
                <div class="file" v-for="(item,index) in fileList" :key="index">
                    <span class="file-name">{{ item.name }}</span>
                    <font-icon
                        class="el-icon__delete"
                        href="#icon-quxiao"
                        @click.native="handleRemoveFile"
                    ></font-icon>
                </div>
            </div>
            <div class="el-upload__text tip">
                <em>Tips：</em>：1、可直接上传自己的Excel。2、姓名、手机{{this.upLoadData.IsFirmResume ? '、城市、公司、职位' : ''}}为必填项。3、只识别文件的第一个Sheet。
            </div>

            <el-button
                class="lbd-upload__next"
                type="primary"
                v-show="!uploadType && ((excelStep == 1 && enableNext ) || excelStep == 2)"
                @click.stop="excelUploadNext"
            >
                下一步
            </el-button>
        </el-upload>
        <div class="excel-match" v-if="!uploadType" v-show="excelStep == 2">
            <div class="excel-match-content">
                <span class="excel-match-title">
                    检测结果：
                </span>
                <field-match
                    ref="fieldMatch"
                    leftFieldTitle="Excel字段"
                    rightFieldTitle="系统字段"
                    :leftFields="excelFields"
                    :rightFields="systemFields"
                    :rightSortRule="systemSortRule"
                    @field-match-result="excelMatchResult"
                ></field-match>
            </div>

            <el-button
                class="lbd-upload__next"
                type="primary"
                v-show="!uploadType && ((excelStep == 1 && enableNext ) || excelStep == 2)"
                @click.stop="excelUploadNext"
            >
                下一步
            </el-button>
        </div>

        <div class="layer-analyze" v-show="loading">
            <div class="screen"></div>
            <div class="layer-body">
                <div class="icon-analyze">
                </div>
                <span>上传中…</span>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import FieldMatch from '#/component/common/field-match.vue';

    let _this = {};
    export default {
        name: "MultipleUpload",
        props: {
            uploadType: {
                default: 1,
                type: Number
            }
        },
        components: {
            FieldMatch
        },
        data() {
            return {
                fileList: [],
                loading: false,
                excelStep: 1,
                excelErrorTip: "",
                excelFields: [],
                systemFields: [],
                excelUploadFileId: "",
                excelUploadRecordId: "",
                excelUploadTotal: 0,
                systemSortRule: {
                    "RealName": 0,
                    "Mobile": 1,
                    "Email": 2,
                    "Location": 3,
                    "Company": 4,
                    "Title": 5,
                    "YearOfExperience": 6,
                    "School": 7,
                    "Degree": 8,
                    "CandidateInquiryLogs": 9,
                    "SourceFrom": 10
                }
            }
        },
        computed: {
            enableNext() {
                return !!this.fileList.length;
            },
            upLoadData() {
                return {
                    IsFirmResume: _getQueryString('type')=='0'? false: true,
                    SourceFrom: this.$store.state.resumeUpload.sourceFrom
                }
            },
            userId(){
                return this.$store.state.user.userInfo.id;
            }
        },
        created() {
            window.uploader = this;
            _this = this;
        },
        mounted() {},
        methods: {
            handleUploadSuccess(response, file, fileList) {
                this.fileList = fileList;
                if (response.succeeded) {

                }
            },
            handleFileListChange: debounce((file, fileList) => {
                if(!_this.uploadType) {
                    let fileName = file.name.slice(0, file.name.lastIndexOf("."));
                    if(fileName.length > 50) {
                        shortTips("文件名长度不能超过50！");
                        return;
                    }
                    if(file.size / 1024 / 1024 > 2) {
                        shortTips("文件大小不得超过2M");
                        return;
                    }
                    _this.fileList = [];
                    _this.fileList.push(file);
                } else {
                    _this.fileList = fileList;
                    _this.$refs.uploader.submit();
                }
            }, 300),
            handleFileLimit(files, fileList){
                shortTips("一次最多上传20份简历");
            },
            handleRemove(file, fileList) {
                this.fileList = fileList;
            },
            handleRemoveFile() {
                window.event.preventDefault();
                window.event.stopPropagation();
                this.$refs.uploader.clearFiles();
                _this.fileList = [];
            },
            // 原组件批量上传会拆分为单文件多次上传，为了适应需求重写为单次批上传
            handleBatchUpload: debounce(() => {
                let formData = new FormData();
                _this.fileList.map((el, index) => {
                    if (el.raw) {
                        formData.append(`file`, el.raw);
                    }
                });
                Object.keys(_this.upLoadData).map((key, index) => {
                    formData.append(key, _this.upLoadData[key]);
                });
                _this.loading = true;
                _request({
                    method: 'POST',
                    url: '/My/Resume/MultipleUpload',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData,
                    throwBusinessError: true
                }).then(res => {
                    if(res && res.recordId){
                        _this.$router.push({
                            name: 'resumeUploadResult',
                            params:{
                                id: res.recordId
                            },
                            query: {
                                uploadType: _this.uploadType
                            }
                        });
                    }
                }).catch(err => {
                    _this.$refs.uploader.clearFiles();
                    _this.loading = false;
                    err.data.message&&shortTips(err.data.message);
                }).finally(() => {
                    _this.loading = false;
                })
            }, 300),
            getSysFields() {
                _request({
                    url: "/My/Candidate/Excel/ImportRule",
                    method: "GET",
                    data: {
                        isPersonal: !this.upLoadData.IsFirmResume
                    }
                }).then(res => {
                    this.systemFields = res;
                    this.$refs.uploader.submit();
                }).catch(err => {
                    console.log(err);
                })
            },
            handleExcelUpload() {
                let formData = new FormData();
                _this.fileList.map((el, index) => {
                    if (el.raw) {
                        formData.append(`file`, el.raw);
                    }
                });
                _this.loading = true;
                _request({
                    method: 'POST',
                    url: '/My/Candidate/Excel/MatchColumn',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    data: formData
                }).then(res => {
                    if(res && res.fileId) {
                        this.excelUploadFileId = res.fileId;
                        this.excelUploadRecordId = res.recordId;
                        this.excelUploadTotal = res.total;
                        this.excelFields = res.candidateImportColumns;
                        this.$nextTick(() => {
                            this.$refs.fieldMatch.init();
                        })
                        this.excelStep = 2;
                    } else {
                        shortTips("excel字段获取失败");
                    }

                }).catch(err => {
                    _this.loading = false;
                }).finally(() => {
                    _this.loading = false;
                })
            },
            excelUploadNext() {
                if(this.excelStep == 1) {
                    this.getSysFields();
                }
                if(this.excelStep == 2) {
                    if(this.excelErrorTip) {
                        shortTips(this.excelErrorTip);
                        return;
                    } else {
                        let params = {
                            FileId: this.excelUploadFileId,
                            IsPersonal: !this.upLoadData.IsFirmResume,
                            RecordId: this.excelUploadRecordId,
                            FileName: this.fileList[0].name,
                            CandidateImportColumns: []
                        };
                        params.CandidateImportColumns = [];
                        this.excelFields.forEach((item) => {
                            if(item.itemName) {
                                let candidateItem = {};
                                candidateItem.importItemName = item.importItemName;
                                candidateItem.itemName = item.itemName;
                                params.CandidateImportColumns.push(candidateItem);
                            }
                        })
                        _tracker.track('ExcelUpload', JSON.stringify({
                            ossFileId: this.excelUploadFileId,
                            createdTime: new Date(),
                            excelFields: this.excelFields,
                            userId: this.userId
                        }));
                        sessionStorage.setItem('excelUploadParams', JSON.stringify(params));
                        _this.$router.push({
                            name: 'resumeUploadResult',
                            params:{
                                id: this.excelUploadRecordId,
                                total: this.excelUploadTotal
                            },
                            query: {
                                uploadType: this.uploadType
                            }
                        });
                    }
                }
            },
            excelMatchResult() {
                this.excelErrorTip = this.$refs.fieldMatch.errorTip;
            }
        }
    }
</script>

<style lang="scss">
    .layout-transition {
        height: 100%;
        overflow-y: auto;
        background-color: #f6f6f6;
        .site-main {
            min-height: 660px;
        }
    }

    .resume-file-upload {
        height: 100%;
        // background: #F5F5F5;
        // background-color: #fff;

        .color-primary {
            color: $primary;
        }

        .file-uploader {
            width: 100%;
            height: 100%;
            min-height: 386px;

            .el-upload {
                display: block;
                height: 100%;
            }

            .el-upload-dragger {
                width: 100%;
                height: 100%;
                min-height: 386px;
                border-color: transparent;
                // border-radius: 0;
                // border-radius: 0 0 8px 8px;
                border-radius: 8px;

                &:hover {
                    border: 1px solid $primary;

                    .el-icon__upload, .el-icon__delete {
                        fill: $primary;
                    }

                    .file-name {
                        color: $primary;
                    }
                }

                .el-icon__upload {
                    margin-top: 120px;
                    margin-bottom: 30px;
                    width: 60px;
                    height: 48px;
                    fill: #999999;
                }

                .el-icon__delete {
                    margin-left: 10px;
                    margin-top: -13px;
                    width: 20px;
                    height: 20px;
                    fill: #999;
                }

                .file {
                    line-height: 20px;
                    height: 20px;
                }

                .file-name {
                    font-weight: bold;
                    display: inline-block;
                    max-width: 800px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .el-upload__text {
                    line-height: 16px;
                    color: #666666;

                    &.tip {
                        margin-top: 10px;
                        font-size: 12px;
                        color: #999999;
                    }
                }

                .el-upload__label {
                    display: block;
                    margin-top: 10px;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: normal;
                    color: #999999;
                }
            }
        }

        .excel-match {
            width: 100%;
            // height: 100%;
            // min-height: 386px;
            min-height: calc(100vh - 140px);
            text-align: center;
            // display: flex;
            // justify-content: center;
            background: #fff;
            // padding: 30px 100px;
            padding: 30px 100px 100px;
            .excel-match-content {
                padding-right: 140px;
                display: flex;
                justify-content: center;
            }
            .excel-match-title {
                font-size: 14px;
                color: #444;
                // margin-right: 200px;
                margin-right: 100px;
            }
            .field-match {

            }
        }

        .lbd-upload__next {
            // position: absolute;
            // right: 20px;
            // bottom: 20px;
            // line-height: 19px;
            cursor: pointer;
            width: 84px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            background: #38BC9D;
            border-radius: 4px;
            color: #fff;
            margin-top: 58px;
            &:hover {

            }
        }

        .el-upload__input {
            display: none;
        }

        .layer-analyze {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;

            .screen {
                width: 100%;
                height: 100%;
                opacity: 0.4;
                background: #000000;
            }

            .layer-body {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: 19px;
                text-align: center;
                color: #fff;

                .icon-analyze {
                    margin-bottom: 20px;
                    width: 55px;
                    height: 55px;
                    background-image: url("~@src/assets/images/upload/layer-analyze.gif");
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                }
            }
        }
    }
</style>
